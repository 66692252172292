<div class="container text-center">
    <img [src]="logo" alt="logo" height="100"/>
</div>

<h1 id="title" class="title">Login</h1>
<p class="sub-title">Seja bem-vindo ao Portal de Compras! Faça login utilizando seu e-mail e senha.</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b> Login Negado! </b></p>
    <ul class="alert-message-list">
        <li class="alert-message">Endereço de email e/ou senha inválidos, tente novamente...</li>
    </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>Login Confirmado!</b></p>
    <ul class="alert-message-list">
        <li class="alert-message">Seu login foi confirmado, redirencionando para o sistema...</li>
    </ul>
</nb-alert>

<div [formGroup]="loginForm" aria-labelledby="title">
    <div class="form-control-group">
        <label class="label" for="input-email">E-mail</label>
        <input nbInput fullWidth id="input-email" formControlName="email" placeholder="Endereço de e-mail">
    </div>
    <div class="form-control-group">
        <span class="label-with-link">
            <label class="label" for="input-password">Senha</label>
        </span>
        <input nbInput fullWidth id="input-password" formControlName="password" type="password" placeholder="Senha">
        <a class="forgot-password caption-2" routerLink="../request-password">Esqueceu a senha?</a>
    </div>

    <!--
    <div class="form-control-group accept-group">
        <nb-checkbox formControlName="rememberMe" name="rememberMe" *ngIf="rememberMe">Lembrar-me</nb-checkbox>
    </div>
    -->

    <button nbButton fullWidth status="primary" size="large" (click)="login()" [disabled]="submitted || !loginForm.valid" [class.btn-pulse]="submitted">Entrar</button>

    <!--
    <div class="form-control-group" style="text-align: center; margin-top:30px;">
        <a [routerLink]="['/auth/register']" class="register-link">Registrar-se</a>
    </div>
    -->
</div>
