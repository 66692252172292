
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { AuthModule } from './@auth/auth.module';
import { FormsModule } from '@angular/forms';

import {
    NbCalendarModule,
    NbDatepickerModule,
    NbDialogModule,
    NbMenuModule,
    NbSidebarModule,
    NbToastrModule,
    NbWindowModule,
} from '@nebular/theme';
import { CacheInterceptor } from './pages/cache.interceptor';
import { MiscellaneousModule } from './pages/miscellaneous/miscellaneous.module';
import { MiscellaneousComponent } from './pages/miscellaneous/miscellaneous.component';
import { NotFoundComponent } from './pages/miscellaneous/not-found/not-found.component';
import { DialogConfirmComponent } from './pages/miscellaneous/dialog-confirm/dialog-confirm.component';
import { ItemSearchComponent } from './pages/miscellaneous/item-search/item-search.component';
import { ProviderSearchComponent } from './pages/miscellaneous/provider-search/provider-search.component';
import { UserSearchComponent } from './pages/miscellaneous/user-search/user-search.component';
import { CompanySearchComponent } from './pages/miscellaneous/company-search/company-search.component';
import { DialogTemplateComponent } from './pages/miscellaneous/dialog-template/dialog-template.component';
import { DialogCancelComponent } from './pages/miscellaneous/dialog-cancel/dialog-cancel.component';
import { DialogDeclineQuoteComponent } from './pages/miscellaneous/dialog-decline-quote/dialog-decline-quote.component';
import { DialogFinalizeQuoteComponent } from './pages/miscellaneous/dialog-finalize-quote/dialog-finalize-quote.component';
import { DialogConfirmOrderComponent } from './pages/miscellaneous/dialog-confirm-order/dialog-confirm-order.component';
import { DialogDeclineOrderComponent } from './pages/miscellaneous/dialog-decline-order/dialog-decline-order.component';
import { DialogParticipateQuoteComponent } from './pages/miscellaneous/dialog-participate-quote/dialog-participate-quote.component';
import { DialogDeclineItemComponent } from './pages/miscellaneous/dialog-decline-item/dialog-decline-item.component';
import { DialogReopenItemComponent } from './pages/miscellaneous/dialog-reopen-item/dialog-reopen-item.component';
import { ChatModule } from './pages/chat/chat.module';
import { AppLogService } from './app-log.service';
import { StorageService } from './@core/backend/portal/services/storage.service';
import { DialogConfirmUserComponent } from './pages/miscellaneous/dialog-confirm-user/dialog-confirm-user.component';
import { DialogDeleteUserComponent } from './pages/miscellaneous/dialog-delete-user/dialog-delete-user.component';
import { DateFilterComponent } from './pages/e-commerce/SimpleDate.Component';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        MiscellaneousModule,
        AuthModule.forRoot(),
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbDialogModule.forRoot(),
        NbWindowModule.forRoot(),
        NbToastrModule.forRoot(),
        NbCalendarModule,
        CoreModule.forRoot(),
        ThemeModule.forRoot(),
        ChatModule
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: ErrorHandler, useClass: AppLogService },
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'pt-BR'},
        StorageService
    ],
    entryComponents: [
        DateFilterComponent,
        MiscellaneousComponent,
        NotFoundComponent,
        DialogConfirmComponent,
        ItemSearchComponent,
        ProviderSearchComponent,
        UserSearchComponent,
        CompanySearchComponent,
        DialogTemplateComponent,
        DialogCancelComponent,
        DialogConfirmUserComponent,
        DialogDeclineQuoteComponent,
        DialogFinalizeQuoteComponent,
        DialogConfirmOrderComponent,
        DialogDeleteUserComponent,
        DialogDeclineOrderComponent,
        DialogParticipateQuoteComponent,
        DialogDeclineItemComponent,
        DialogReopenItemComponent
    ]
})
export class AppModule {
}
