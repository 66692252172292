

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserStore } from '../../../@core/stores/user.store';
import { SettingsData } from '../../../@core/interfaces/common/settings';
import { User } from '../../../@core/interfaces/common/users';
import { ChatService } from '../../../pages/chat/chat.service';

export interface UserInfo {
    name: string;
    title: string;
}

export interface UserMenu {
    title: string,
    link: string,
    queryParams?: {
        profile: boolean
    }
}

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();
    logo: string = this.changeLogo(this.themeService.currentTheme);
    userPictureOnly: boolean = false;
    isReady: boolean = false;
    userInfo: UserInfo = null;
    userMenu: UserMenu[] = null;
    user: User = null;

    themes = [
        {
            value: 'default',
            title: 'Claro',
        },
        {
            value: 'dark',
            title: 'Escuro',
        }
    ];

    currentTheme = 'default';

    private readonly roleTitle: Map<number, string> = new Map<number, string>([
        [1, 'Administrador'],
        [2, 'Comprador'],
        [3, 'Fornecedor']
    ]);

    get iconState(): boolean {
        return this.chatService.bShowHideChatListIcon;
    }

    constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private userStore: UserStore,
        private settingsService: SettingsData,
        private layoutService: LayoutService,        
        private breakpointService: NbMediaBreakpointsService,
        private chatService: ChatService) {
    }

    async ngOnInit(): Promise<void> {
        this.currentTheme = this.themeService.currentTheme;

        this.userStore.onUserStateChange()
            .pipe(takeUntil(this.destroy$))
            .subscribe(async user => {
                this.user = user;
                await this.setDataSafe();
            });

        this.selectTheme();

        const { xl } = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$),
            )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

        this.themeService.onThemeChange()
            .pipe(
                map(({ name }) => name),
                takeUntil(this.destroy$),
            )
            .subscribe(themeName => this.currentTheme = themeName);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private async setDataSafe(ms: number = 0): Promise<void> {
        if (ms > 1500) {
            this.setData();
        }
        else if (this.user !== null) {
            this.setData();
        }
        else {
            ms += 50;
            await this.delay(ms);
            this.setDataSafe(ms);
        }
    }

    private delay(ms: number): Promise<NodeJS.Timeout> {
        return new Promise(_ => setTimeout(_, ms));
    }

    private setData(): void {
        this.setUserInfo();
        this.setMenuItems();
        this.isReady = true;
    }

    private setUserInfo(): void {
        this.user !== null ? this.setInfo() : this.setDefaultInfo();
    }

    private setInfo(): void {
        this.userInfo = {
            name: this.user.firstName.length > 15 ? `${this.user.firstName.slice(0, 15)}...` : this.user.firstName,
            title: this.roleTitle.get(this.user.userRoles[0].roleId)
        }
    }

    private setDefaultInfo(): void {
        this.userInfo = {
            name: 'Usuário',
            title: 'Desconhecido'
        };
    }

    private setMenuItems() {
        this.userMenu = [
            { title: 'Perfil', link: this.user ? '/pages/users/current/' : '', queryParams: { profile: true } },
            { title: 'Alterar Senha', link: '/auth/reset-password' },
            { title: 'Sair', link: '/auth/logout' },
        ];
    }

    private changeTheme(themeName: string) {
        this.userStore.setSetting(themeName);
        this.settingsService.updateCurrent(this.userStore.getUser().settings)
            .pipe(takeUntil(this.destroy$))
            .subscribe();
        this.themeService.changeTheme(themeName);
        this.logo = this.changeLogo(themeName);
    }

    private selectTheme() : void {
        this.menuService.onItemClick()
            .pipe(
                filter(({tag}) => tag === 'theme-switcher'),
                map(({ item: { title } }) => {
                    var newTheme: string;
                    this.themes.forEach(theme => {
                        if (theme.title === title) newTheme = theme.value;
                    });
                    return newTheme;
                })
            )
            .subscribe(theme => this.changeTheme(theme))
    }

    private changeLogo(themeName: string) : string {
        return themeName === 'default' ?
            "../../../../assets/images/logo.fw.png" :
            "../../../../assets/images/logo_white.fw.png";
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();

        return false;
    }

    navigateHome() {
        this.menuService.navigateHome();
        return false;
    }
}
