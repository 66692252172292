import { ProviderApi } from '../api/provider.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridData } from '../../../interfaces/common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Quote, QuoteGrid, QuoteData, TransactionHistoricDTO } from '../../../interfaces/portal/quote';
import { QuoteApi } from '../api/quote.api';
import { RequestResponse } from '../../../interfaces/portal/company';
import { HangfireResponse } from '../../../interfaces/portal/hangfire';

@Injectable()
export class QuoteService implements QuoteData {

  constructor(private api: QuoteApi) { }

  get gridDataSource(): DataSource {
    return this.api.requestDataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<QuoteGrid>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<Quote> {
    return this.api.get(id);
  }

  create(order: any): Observable<RequestResponse> {
    return this.api.add(order);
  }

  update(order: any): Observable<RequestResponse> {
    return this.api.update(order);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  getNextNumQuote(): Observable<string> {
    return this.api.getNextNumQuote();
  }

  submitQuoteToProvider(id: number): Observable<Quote> {
    return this.api.submitQuoteToProvider(id);
  }

  changeStatus(objectHistoric: TransactionHistoricDTO): Observable<any> {
    return this.api.changeStatus(objectHistoric);
  }

  quoteLog(): Observable<RequestResponse<HangfireResponse[]>> {
    return this.api.quoteLog();
  }
}
