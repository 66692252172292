<div class="modal-container" (click)="cancel()">
  <nb-card class="user-search-card" (click)="stopPropagation($event)">
    <nb-card-header>
      <div class="row">
        <h5 class="col-sm-11 d-flex align-items-center">Selecione um usuário</h5>
        
        <div class="col-sm-1 col text-right">
          <button nbButton status="primary" class="btn-close" (click)="cancel()" hero>
            <i class="nb-close"></i>
          </button>
        </div>
      </div>
    </nb-card-header>
  
    <nb-card-body>
      <ng2-smart-table 
        class="request-smart-table"
        [settings]="settings"
        [source]="source"
        (edit)="onEdit($event)">
      </ng2-smart-table>
    </nb-card-body>
  </nb-card>
</div>
