
import { Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

import { NbAuthService } from '@nebular/auth';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-auth',
  styleUrls: ['./auth.component.scss'],
  template: `
    <nb-layout>
      <nb-layout-column>
        <nb-card>
          <nb-card-header *ngIf="!isLogin">
            <nav class="navigation">
              <a href="#" (click)="back()" class="link back-link" aria-label="Back">
                <nb-icon icon="arrow-back"></nb-icon>
              </a>
            </nav>
          </nb-card-header>
          <nb-card-body>
            <nb-auth-block>
              <router-outlet></router-outlet>
            </nb-auth-block>
          </nb-card-body>
        </nb-card>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class NgxAuthComponent implements OnDestroy {
  private alive = true;
  private unsubscribe$ = new Subject<void>();

  subscription: any;

  isLogin: boolean = false;
  authenticated: boolean = false;
  token: string = '';

  // showcase of how to use the onAuthenticationChange method
  constructor(
    protected auth: NbAuthService,
    protected location: Location,
    private router: Router) {
      this.router.events
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => this.isLoginPage());

      this.subscription = auth.onAuthenticationChange()
        .pipe(takeWhile(() => this.alive))
        .subscribe((authenticated: boolean) => {
          this.authenticated = authenticated;
        });
  }

  isLoginPage(): void {
    this.isLogin = window.location.href.endsWith('/auth/login');
  }

  back() {
    this.location.back();
    return false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.alive = false;
  }
}
